import React, { useRef, useEffect } from 'react';
import { Feature } from '../index';
import { ComparisonCell } from '../Comparison.style';
import { StyledComparisonCard } from './ComparisonCard.style';
import { PricingCell } from '../PricingCell/PricingCell';
import { animateChildren, resetAnimationStyles } from '../animateChildren';
import ShowOnScroll from '../../ShowOnScroll/ShowOnScroll';

export const ComparisonCard: React.FC<ComparisonCardProps> = ({
  title,
  price,
  fee,
  description,
  features
}) => {
  //#region Hooks / Lifecycles
  const cellWrappersRef = useRef<Element[]>([]);

  useEffect(() => {
    if (cellWrappersRef.current.length) {
      resetAnimationStyles(cellWrappersRef.current);
    }
  }, []);
  //#endregion

  //#region Functions
  function addToRefs(ref: HTMLElement) {
    if (!cellWrappersRef.current.includes(ref)) {
      cellWrappersRef.current.push(ref);
    }
  }

  function onVisible(): void {
    animateChildren(cellWrappersRef.current, [5, 1]);
  }
  //#endregion

  return (
    <StyledComparisonCard>
      <ShowOnScroll
        passive={ true }
        onVisible={ onVisible }
        delay={ 500 }>
        <ComparisonCell className="cell cell--head" type="head" ref={ ref => addToRefs(ref) }>
          <h2 className="title">{ title }</h2>
        </ComparisonCell>

        <PricingCell className="cell cell--pricing" price={ price } fee={ fee } ref={ ref => addToRefs(ref) } />

        <ComparisonCell className="cell cell--description" type="head" ref={ ref => addToRefs(ref) }>
          { typeof description === 'string' ? description : description() }
        </ComparisonCell>

        { features.map((feat, i) => {
          return (
            <ComparisonCell key={ feat.featureTitle } className="cell cell--feature" ref={ ref => addToRefs(ref) }>
              <h3 className="title">{ feat.featureTitle }</h3>

              { feat.featuresList?.length && (
                <ul className="featureList">
                  { feat.featuresList.map(i => {
                    return <li className="featureList__item" key={ i.feature }>{ i.feature }</li>;
                  }) }
                </ul>
              ) }
            </ComparisonCell>
          );
        }) }
      </ShowOnScroll>
    </StyledComparisonCard>
  );
}

interface ComparisonCardProps {
  title: string;
  price: string;
  fee: string;
  description: string | (() => JSX.Element);
  features: Feature[];
}
