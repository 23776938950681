import React, { useEffect, useRef } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledContactForm } from './ContactForm.styled';
import { Form } from '../Form/Form';
import { Input } from '../Form/Input';
import { Select } from '../Form/Select';
import { StyledBtn } from '../Cta/Cta.styled';
import Recaptcha from 'react-recaptcha';
import useSSR from 'use-ssr'


const ContactFormTemplate = ({ data }: MarkdownData<ContactFormData>) => {
  //#region Hooks / Lifecycles
  const boxRef = useRef<HTMLDivElement>();
  const recaptchaTimer = useRef<number>();

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { title, submitText } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Functions
  const init = (): void => {
    recaptchaTimer.current = window.setInterval(recaptchaInterval, 500);
  }

  const destroy = (): void => {
    window.clearInterval(recaptchaTimer.current);
    recaptchaTimer.current = null;
  }

  const onFullNameChange = (e: React.FormEvent) => {
    const input: HTMLInputElement = e.target as HTMLInputElement;
    const fnameInput: HTMLInputElement = boxRef.current.querySelector('input[name="first_name"]');
    const lnameInput: HTMLInputElement = boxRef.current.querySelector('input[name="last_name"]');
    let nameArr: string[] = input.value.trim().split(' ');

    if (nameArr.length === 1) {
      fnameInput.value = nameArr.join('');
    } else {
      lnameInput.value = nameArr.pop();
      fnameInput.value = nameArr.join(' ');
    }
  }

  const recaptchaInterval = (): void => {
    var response: HTMLInputElement = document.getElementById('g-recaptcha-response') as HTMLInputElement;

    if (response == null || response.value.trim() == '') {
      var elems = JSON.parse(
        (document.getElementsByName('captcha_settings')[0] as HTMLInputElement).value
      );
      elems['ts'] = JSON.stringify(new Date().getTime());
      (document.getElementsByName('captcha_settings')[0] as HTMLInputElement).value = JSON.stringify(elems);
    }
  }

  const beforeSubmit = (e: React.FormEvent): boolean => {
    const form = e.target as HTMLFormElement;
    const honeypot: HTMLInputElement = form.querySelector('#shouldnt-exist');
    const recaptchaResponse = document.getElementById('g-recaptcha-response') as HTMLInputElement;
    const recaptchaError = document.querySelector('.recaptchaError') as HTMLElement;

    if (recaptchaResponse.value === '') {
      recaptchaError.classList.add('visible');
      return false;
    }

    return honeypot.value === '';
  }
  //#endregion

  //#region Templating
  const { isBrowser } = useSSR()
  return (
    <div className="box" ref={boxRef}>
      <h2 className="title">{title}</h2>
      <Form method="post" action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" onBeforeSubmit={beforeSubmit}>
        {/* Make sure you split full name into first/last before sending */}
        <input type="hidden" name="first_name" />
        <input type="hidden" name="last_name" />
        <input type="hidden" name="lead_source" id="m_lead_source" value="Website Form" />
        <input type="hidden" name="retURL" value={typeof window === 'undefined' ? '' : `${window.location.protocol}//${window.location.host}/#thank-you`} />
        <input type="hidden" name="oid" value="00D5e000002SmEE" />

        <input
          type="hidden"
          name="captcha_settings"
          value='{"keyname":"ChordSite","fallback":"true","orgId":"00D5e000002SmEE","ts":""}' />

        <div className="inputGrid">
          <Input name="Full Name" required onChange={onFullNameChange} />
          <Input name="title" placeholder="Title" required />
          <Input name="company" placeholder="Company" maxlength={40} required />

          <Input type="email" name="email" id="contact_email" placeholder="Email" maxlength={80} required />
          <Input name="url" placeholder="Website" />
          <Select name="00N5e00000eZHM3" id="00N5e00000eZHM3" placeholder="I am a..." required choices={[
            { value: "Prospect", label: "Brand" },
            { value: "Agency Partner", label: "Agency" },
            { value: "Technology Partner", label: "Software Provider" },
            { value: "Investor Partner", label: "Investor" }
          ]} />

          <StyledBtn type="submit">{submitText}</StyledBtn>
          <div className="recaptcha">
            {isBrowser && <Recaptcha
              sitekey="6LeEt5ccAAAAAP2PluRBpdWM_VNDBNdcdDMBqaPF"
            />
            }
            <span className="recaptchaError">reCaptha failed to validate, please check the box above</span>
          </div>
        </div>
        <input
          style={{ display: "none" }}
          id="shouldnt-exist"
          name="shouldnt-exist"
          type="text"
        />
      </Form>
    </div>
  )
}

const ThankYouTemplate = ({ data }: MarkdownData<ContactFormData>) => {
  const { thankYouTitle, thankYouText } = data.markdownRemark?.frontmatter || {};
  return (
    <div className="box box--thanks" id="thank-you">
      <h2 className="title">{thankYouTitle}</h2>
      <p className="text">{thankYouText}</p>
    </div>
  )
}

const Template = ({ data }: MarkdownData<ContactFormData>) => {
  const showThankYou = useRef<boolean>(typeof window === 'undefined' ? null : window.location.hash === '#thank-you');

  const { sectionId } = data.markdownRemark?.frontmatter || {};
  //#region Templating
  return (
    <StyledContactForm id={sectionId}>
      <Wrapper>
        {showThankYou.current
          ? <ThankYouTemplate data={data} />
          : <ContactFormTemplate data={data} />
        }
      </Wrapper>
    </StyledContactForm>
  )
  //#endregion
}

export const ContactFormPreview: React.FC<ContactFormData> = (data: ContactFormData) => {
  return (
    <>
      <Template data={{
        markdownRemark: {
          frontmatter: data
        }
      }} />

      <ThankYouTemplate data={{
        markdownRemark: {
          frontmatter: data
        }
      }} />

    </>
  )
}

export const Contactform: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/contactform.md/"}) {
        frontmatter {
          sectionId
          title
          submitText
          thankYouTitle
          thankYouText
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

interface ContactFormData {
  sectionId: string;
  title: string;
  submitText: string;
  thankYouTitle: string;
  thankYouText: string;
}

