import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import { Section } from '../styles/Section.style';

import { HeroBanner } from '../components/HeroBanner/HeroBanner';
import { DemoBar } from '../components/DemoBar/DemoBar';
import { Valueprops } from '../components/ValueProps/ValueProps';
import { TeamListing } from '../components/TeamListing/TeamListing';
import { Fiftyfifty } from '../components/FiftyFifty/FiftyFifty';
import { Divider } from '../components/Divider/Divider';
import { Testimonials } from '../components/Testimonials/Testimonials';
import { Partnerslisting } from '../components/PartnersListing/PartnersListing';
import { Comparison } from '../components/Comparison/Comparison';
import { Imagetext } from '../components/ImageText/ImageText';
import { SEO } from '../components/SEO';
import { Contactform } from '../components/ContactForm/ContactForm';

const Home: React.FC = () => {
  return (
    <StyleSheetManager disableVendorPrefixes={process.env.NODE_ENV === 'development'}>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO />
          <HeroBanner />
          <DemoBar />
          <Section bg="cream">
            <Valueprops />
          </Section>
          <Section bg="charcoal">
            <Fiftyfifty />
            <Divider />
            <Testimonials />
            <Divider />
            <Partnerslisting id={1} />
          </Section>
          <Section bg="cream">
            <TeamListing />
            <Divider color="charcoal" />
            <Partnerslisting id={2} />
            <Imagetext />
            <Comparison />
            <Partnerslisting id={3} titleSize="small" />
            <Contactform />
          </Section>
        </Layout>
      </ThemeProvider>
    </StyleSheetManager>
  )
};

export default Home;