import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';

import { Wrapper } from '../../styles/Wrapper.style';
import { StyledOffsetLine } from './OffsetLines.styled';
import { VisibilityManager } from '../../services/VisibilityManager';

let offsetLinesIndex: number = 0;

const mq: MediaQueryList = typeof window !== 'undefined' ? window.matchMedia('(max-width: 991px)') : null;

const visibilityManager: VisibilityManager = VisibilityManager.getInstance();

export const OffsetLines: React.FC<OffsetLineProp> = ({ lines, tag = 'div', position = 'center', yOffset = 0, width = 1096, animateIn = false }) => {
  //#region Hooks
  offsetLinesIndex++;
  const linesRef = useRef<HTMLDivElement>(null);

  const holderRef = useRef<HTMLDivElement>(null);

  const childrensRef = useRef<HTMLCollection>(null);

  const visibilitySubscribtion = useRef<Symbol>();

  const hasAnimated = useRef<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    hasAnimated.current = false;
    init()

    return () => {
      destroy();
      setTimeout(() => {
        hasAnimated.current = false;
      })
    }
  }, [])
  //#endregion

  //#region Variables
  const Tag = `${tag}` as keyof JSX.IntrinsicElements;
  //#endregion

  //#region Functions

  const init = (): void => {
    childrensRef.current = linesRef.current.children;


    if (typeof window === 'undefined') return;
    visibilitySubscribtion.current = visibilityManager.subscribe(holderRef.current, onIntersection);

  }

  const onIntersection = (entry: IntersectionObserverEntry): void => {
    if (entry.isIntersecting) {
      initOnVisible();
    } else {
      destroy();
    }
  }

  const initOnVisible = (): void => {
    bindEvents();

    gsap.ticker.add(tick);
    if (animateIn && !hasAnimated.current) {
      fadeIn();
    }

    if (!mq.matches) {
      bindVolatileEvents();
    }
  }

  const destroy = (): void => {
    unbindEvents();
    unbindVolatileEvents();
    gsap.ticker.remove(tick);
  }

  const bindEvents = (): void => {
    mq.addEventListener('change', onMQChange);
  }

  const unbindEvents = (): void => {
    mq.removeEventListener('change', onMQChange);
  }

  const bindVolatileEvents = (): void => {
    document.addEventListener('scroll', onScroll);
  }

  const unbindVolatileEvents = (): void => {
    document.removeEventListener('scroll', onScroll);
  }

  const onMQChange = (e: MediaQueryListEvent): void => {
    if (e.matches) {
      unbindVolatileEvents();
      setTimeout(() => {
        resetLines();
      });
    } else {
      bindVolatileEvents();
    }
  }

  const onScroll = (): void => {

    gsap.ticker.add(tick);
  }

  const resetLines = (): void => {
    gsap.set(childrensRef.current, { x: 0 });
  }

  const fadeIn = (): void => {
    if (childrensRef.current) {
      gsap.fromTo(childrensRef.current, {
        y: 25
      }, {
        y: 0,
        autoAlpha: 1,
        duration: 2,
        stagger: 0.1,
        ease: 'power4.out',
        onComplete: () => {
          hasAnimated.current = true;
        }
      });
    }
  }

  const tick = (): void => {

    if (mq.matches) return;
    for (let i = 0; i < childrensRef.current.length; i++) {
      const element = childrensRef.current[i] as HTMLElement;
      moveLine(element, i);
    }

    if (getScroll() * -1 == window.scrollY) {
      gsap.ticker.remove(tick);
    }
  }

  const moveLine = (element: HTMLElement, i: number) => {
    let distance = getDistance(element);

    if (i % 2 !== 0) {
      distance = distance * -1;
    }

    gsap.set(element, { x: distance * 0.3 });
  }

  const getScroll = (): number => window.easedScroll || window.scrollY * -1;

  const getDistance = (element: HTMLElement): number => {
    const parent: HTMLElement = element.parentElement as HTMLElement;
    let targetPosition: number;
    if (position === 'top') {
      targetPosition = parent.offsetTop - 70;
    } else if (position === 'center') {
      targetPosition = parent.offsetTop - window.innerHeight / 2 + parent.offsetHeight / 2;
    } else if (position === 'bottom') {
      targetPosition = parent.offsetTop - window.innerHeight + parent.offsetHeight;
    }

    return (getScroll() * -1) - targetPosition + yOffset;
  }
  //#endregion

  return (
    <StyledOffsetLine animateIn={animateIn} hasAnimated={hasAnimated.current} ref={holderRef}>
      <Wrapper width={width}>
        <Tag className="holder">
          <div className="lines" ref={linesRef}>
            {lines.map((line: OffsetLine, i: number) => {
              const style = {
                '--offset': `${line.offset}px`,
                '--offsetVW': `${line.offset * 100 / 1440}vw`
              } as React.CSSProperties;
              return <span className="line" key={`offset-${offsetLinesIndex}-${i}`} style={style}>{line.text}</span>
            })}
          </div>
        </Tag>
      </Wrapper>
    </StyledOffsetLine>
  )
}

interface OffsetLineProp {
  lines: OffsetLine[];
  width?: number;
  tag?: string;
  position?: 'top' | 'center' | 'bottom';
  yOffset?: number;
  animateIn?: boolean;
}

export interface OffsetLine {
  text: string;
  offset?: number;
}