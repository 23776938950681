import React, { useRef } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { GatsbyImage, GatsbyImageProps, ImageDataLike } from 'gatsby-plugin-image';
import { PactImage } from '../PactImage/PactImage';
import { StyledTestimonials } from './Testimonials.styled';
import { Wrapper } from '../../styles/Wrapper.style';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';

let testimonialsIndex: number = 0;
const Template = ({ data }: MarkdownData<TestimonialsData>) => {
  //#region Variables
  testimonialsIndex++;

  const { testimonials, sectionId, forcedIndex } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Templating
  return (
    <StyledTestimonials>
      <Wrapper id={sectionId}>
        <div className="items">
          {testimonials.map((testimonial, i: number) => {
            const { logo, author_role, author, avatar, quote, companyUrl, company_name } = testimonial;
            return (
              <ShowOnScroll key={`testimonial-${forcedIndex || testimonialsIndex}-${i}`}>
                <div className="item">
                  <div className="logo">
                    <PactImage addMaxWidth={true} image={logo} alt={company_name} />
                  </div>
                  <p className="quote">“{quote}”</p>
                  <div className="info">
                    <div className="avatar">
                      <PactImage image={avatar} alt={author} />
                    </div>
                    <div className="author-info">
                      <span className="author">{author}</span>
                      <span className="role">
                        {author_role}{companyUrl && company_name && (
                          <span>, <a target="_blank" href={companyUrl}>{company_name}</a></span>
                        )}</span>
                    </div>
                  </div>
                </div>
              </ShowOnScroll>
            )
          })}
        </div>
      </Wrapper>
    </StyledTestimonials>
  )
  //#endregion
}

export const TestimonialsPreview: React.FC<TestimonialsData> = (data: TestimonialsData) => <Template data={{
  markdownRemark: {
    frontmatter: {...data, forcedIndex: 'preview' }
  }
}} />

export const Testimonials: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/testimonials.md/"}) {
        frontmatter {
          sectionId
          testimonials {
            logo {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                )
              }
            }
            quote
            avatar {
              childImageSharp {
                gatsbyImageData(
                  width: 98
                  height: 98
                  transformOptions: {fit: COVER}
                  placeholder: BLURRED
                )
              }
            }
            author
            author_role
            company_name
            companyUrl
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

interface TestimonialsData {
  sectionId: string;
  forcedIndex?: string;
  testimonials: {
    logo: ImageDataLike | string;
    quote: string;
    avatar: ImageDataLike | string;
    author: string;
    author_role: string;
    company_name: string;
    companyUrl: string;
  }[];
}