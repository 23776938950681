import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem } from '../../utils/Tools';
import { StyledOffsetLine } from '../OffsetLines/OffsetLines.styled';
import { StyledComparisonCard } from './ComparisonCard/ComparisonCard.style';

export const StyledComparison = styled.div<StyledComparisonProps>`
  ${StyledOffsetLine} {
    margin-bottom: 58px;
  }

  .comparison-wrapper {
    position: relative;
    z-index: 1;
  }

  .comparison-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gutter);
  }

  .circle1, .circle2 {
    --color: var(--white);
    position: absolute;
    z-index: -1;
  }

  .circle1 {
    top: -120px;
    left: calc(100% - 75px);
  }

  .circle2 {
    bottom: -7px;
    right: calc(100% - 235px);
  }

  @media (max-width: 767px) {
    ${StyledOffsetLine} {
      margin-bottom: 24px;
    }
    
    .circle1 {
      left: calc(100% - 25px);
    }

    .circle2 {
      bottom: 183px;
      right: calc(100% - 65px)
    }

    .comparison-cards {
      display: block;
      
      ${ StyledComparisonCard } {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    } 
  }
`;

interface StyledComparisonProps {
  isMobile: boolean;
}

export const ComparisonCell = styled.div<ComparisonCell>`
  padding: 20px 20px 30px 40px;

  ${ p => p.type === 'head' ? `
    padding: 50px 30px 40px;
    text-align: center;

    .h1, h2, h3, h4, h5, h6 {
      position: relative;
      display: table;
      margin: 0 auto .57em;
      font-size: 52px;
      line-height: 1.2;
      padding-top: 1px;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--pink);
      }

      @media (max-width: 1349px) {
        font-size: 40px;
      }
    }

    p {
      font: 400 ${ pxtorem(20) }/1.5 var(--primaryFont);
      margin: 0;
    }
  ` : p.type === 'icon' || p.type === 'pricing' ? `
    display: flex;
    justify-content: center;
    align-items: center;
  ` : '' }

  ${ p => p.type === 'icon' ? `
    svg {
      display: block;
      width: 69px;
      height: 52px;
      fill: var(--pink);
    }
  ` : '' }

  ${ p => p.type === 'pricing' ? `
    padding: 28px 28px 38px;
  ` : '' }

  .featureList {
    padding-left: 8px;
    list-style-position: inside;

    &__item {
      font: 400 14px/1.7 var(--primaryFont);
      letter-spacing: -0.05em;
    }
  }
`;

interface ComparisonCell {
  type?: 'head' | 'icon' | 'pricing';
}
