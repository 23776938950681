import styled from 'styled-components/macro';

export const StyledOffsetLine = styled.div<StyledOffsetLineProp>`
  .holder {
    margin: 0;
  }
  .lines {
    display: block;
  }

  .line {
    display: block;
    ${p => p.animateIn && !p.hasAnimated ? `
      visibility: hidden;
      opacity: 0;
    `: null}
    font: 600 max(6.42916vw, 40px)/max(7.78125vw,48px) var(--primaryFont);
    letter-spacing: -0.05em;
    position: relative;
    will-change: transform;
    left: var(--offsetVW);
    
    @media (min-width: 1440px) {
      // You should learn from this LG2
      font: 600 92px/112px var(--primaryFont);
      left: var(--offset);
    }
  }

  @media (max-width: 991px) {
    .line{
      left: 0;
    }
  }
`;

interface StyledOffsetLineProp {
  animateIn: boolean;
  hasAnimated?: boolean;
}