import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { OffsetLine, OffsetLines } from '../OffsetLines/OffsetLines';
import { StackedCircles } from '../StackedCircles/StackedCircles';
import { StyledHeroBanner } from './HeroBanner.styled';

const Template: React.FC<MarkdownData<HeroBannerData>> = ({ data }) => {
  //#region Hooks
  const boxRef = useRef<HTMLDivElement>(null);

  const boxWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { title_line, boxText, boxTitle } = data.markdownRemark?.frontmatter || {};
  //#endregion


  //#region Functions
  const init = (): void => {
    initGSAP();
    fadeInBox();
  }

  const destroy = (): void => {
    destroyGSAP();
  }

  const fadeInBox = (): void => {
    gsap.to(boxWrapperRef.current, {
      autoAlpha: 1,
      y: 0,
      duration: 2,
      delay: .2,
      ease: 'power4.out'
    });
  }

  const initGSAP = (): void => {
    gsap.registerPlugin(ScrollTrigger);

    const tl: gsap.core.Timeline = gsap.timeline({ paused: true });

    tl.to(boxRef.current, {
      autoAlpha: 0,
      ease: 'none'
    });

    ScrollTrigger.matchMedia({
      '(min-width:992px)': () => {
        ScrollTrigger.create({
          animation: tl,
          start: 'top top',
          end: 200,
          scrub: .1
        });
      },
      '(max-width: 991px)': () => {
        gsap.set([boxRef.current], { clearProps: 'all' });
      }
    })

  }

  const destroyGSAP = () => {
    ScrollTrigger.getAll().map(st => st.kill(false, true));
    gsap.killTweensOf([boxRef.current, boxWrapperRef.current]);
  }
  //#endregion

  return (
    <StyledHeroBanner>
      <Wrapper>
        <div className="circle2">
          <StackedCircles rotate={{ x: 34, y: -36, z: 0 }} />
        </div>
      </Wrapper>
      <OffsetLines animateIn={true} lines={title_line} position='top' yOffset={-61} tag="h1" />
      <Wrapper className="boxWrapper" width={1240} ref={boxWrapperRef}>
        <div className="box" ref={boxRef}>
          <h2 className="box__title">{boxTitle}</h2>
          {boxText && (
            <p className="box__text">{boxText}</p>
          )}
        </div>
        <div className="circle1">
          <StackedCircles delay={0.5} rotate={{ x: 34, y: -36, z: 0 }} />
        </div>
      </Wrapper>
    </StyledHeroBanner>
  )
};

export const HeroBanner: React.FC = () => {
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/herobanner.md/"}) {
        frontmatter {
          title_line {
            offset
            text
          }
          boxTitle
          boxText
        }
      }
    }
  `;

  return (
    <StaticQuery query={query} render={data => <Template data={data} />} />
  )
};

export const HeroBannerPreview: React.FC<HeroBannerData> = (data: HeroBannerData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

interface HeroBannerData {
  title_line: {
    offset: number;
    text: string;
  }[];
  boxTitle: string;
  boxText: string;
}