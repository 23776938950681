import React, { useEffect, useRef } from 'react';
import { StyledForm } from './Form.styled';

export const Form: React.FC<FormProps> = ({
  action = '#',
  method = 'get',
  children,
  className,
  onBeforeSubmit,
  onAfterSubmit,
  customSubmit
}) => {

  //#region Functions
  const onSubmit = (e: React.FormEvent): boolean => {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    form.classList.toggle('invalid', !form.checkValidity());

    if (onBeforeSubmit) {
      if (onBeforeSubmit(e) === false) {
        return false;
      }
    }

    if (customSubmit) {
      customSubmit(e);
    } else {
      (e.target as HTMLFormElement).submit();
    }

    if (onAfterSubmit) {
      onAfterSubmit(e);
    }

    return false;
  }
  //#endregion

  //#region Templating
  return (
    <StyledForm action={action} method={method} onSubmit={onSubmit} className={className}>
      {children}
    </StyledForm>
  )
  //#endregion
}

interface FormProps {
  action?: string;
  method?: 'get' | 'post';
  className?: string;
  customSubmit?(e: React.FormEvent): void;
  onBeforeSubmit?(e: React.FormEvent): boolean;
  onAfterSubmit?(e: React.FormEvent): void;
}