import styled, { css } from 'styled-components';

export const StyledPartnersListing = styled.div`
  padding: 60px 0;

  .title {
    text-align: center;
    font: 600 24px var(--primaryFont);
    letter-spacing: -0.05em;
    margin-bottom: 64px;

    &--normal {
      @media (max-width: 768px) {
        margin-bottom: 48px;
      }
    }

    &--small {
      font: 400 14px var(--primaryFont);
      letter-spacing: unset;
      margin-bottom: 32px;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 40px 80px;
  }

  .item {
    transition: 1s cubic-bezier(.22, 1, .36, 1);
    transition-property: transform, opacity;
    opacity: 0;
    transform: translateY(50px);

    ${[...Array(10)].map((x, i: number) => (css`
        &:nth-child(${i}) {
          transition-delay: ${i * 0.05}s;
        }
      `
    ))}

    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }

  .is-visible .item {
    opacity: 1;
    transform: translateY(0);
  }
`