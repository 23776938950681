import React, { useEffect } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { PactImage } from '../PactImage/PactImage';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledPartnersListing } from './PartnersListing.styled';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';

let partnersListingIndex: number = 0;
const Template = ({ allData, id, titleSize = 'normal' }: PartnersProps) => {
  //#region Variables
  partnersListingIndex++;

  const data = allData.allMarkdownRemark?.nodes.find(
    node => node.fileAbsolutePath.split('/').pop().indexOf(`partners${id}.md`) > -1
  );

  const { title, partners, } = data.frontmatter || {};
  //#endregion

  //#region Templating
  return (
    <StyledPartnersListing>
      <Wrapper>
        {title && (
          <ShowOnScroll>
            <h3 className={`title title--${titleSize}`}>{title}</h3>
          </ShowOnScroll>
        )}

        <ShowOnScroll passive={true} visibleClassName="is-visible">
          <div className="items">
            {partners.map((partner, i: number) => {
              const { partnerLogo, partnerName } = partner;
              return (
                <div className="item" key={`partners-${partnersListingIndex}-${i}`}>
                  <PactImage addMaxWidth={true} image={partnerLogo} alt={partnerName} />
                </div>
              )
            })}
          </div>
        </ShowOnScroll>
      </Wrapper>
    </StyledPartnersListing>
  )
  //#endregion
}

export const PartnerslistingPreview: React.FC<PartnersData> = (data: PartnersData) => {
  const props: PartnersProps = {
    allData: {
      allMarkdownRemark: {
        nodes: [
          {
            fileAbsolutePath: '/partners1.md',
            frontmatter: data
          }
        ]
      }
    },
    id: 1
  }
  return <Template {...props} />
}

export const Partnerslisting: React.FC<StaticQueryProps> = (props) => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/partners(.*).md/"}}) {
        nodes {
          fileAbsolutePath
          frontmatter {
            title
            partners {
              partnerName
              partnerLogo {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template allData={data} {...props} />} />
  //#endregion
}

interface StaticQueryProps {
  id: number;
  titleSize?: 'normal' | 'small';
}

interface PartnersProps extends StaticQueryProps {
  allData: AllMarkdownData<PartnersData>['data'];
}

interface PartnersData {
  title: string;
  partners: {
    partnerName: string;
    partnerLogo: ImageDataLike | string;
  }[]
}