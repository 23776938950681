import React, { useEffect, useRef } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { StyledDemoBar } from './DemoBar.styled';
import { StyledBtn } from '../Cta/Cta.styled';
import { Wrapper } from '../../styles/Wrapper.style';
import { Form } from '../Form/Form';
import { scrollToSelector } from '../../utils/Tools';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';

const Template = ({ data, topBG = 'charcoal', barBG = 'black', bottomBG = 'cream' }: DemoBarProps) => {
  //#region Hooks / Lifecycles
  const recaptchaTimer = useRef<number>();

  useEffect(() => {
    if (typeof window === 'undefined') return;
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables
  const { boxTitle, chordVersion, formPlaceholder, formSubmit } = data.markdownRemark?.frontmatter || {};

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }

  const syncEmail = (e: React.FormEvent): void => {
    const form = e.target as HTMLFormElement;
    const email: HTMLInputElement = form.querySelector('input[name="email"]');
    const contactFormEmail: HTMLInputElement = document.querySelector('#contact_email');
    const contactFormFullName: HTMLInputElement = document.querySelector('#full-name');

    if (!contactFormEmail || !contactFormFullName) return;
    contactFormEmail.value = email.value;

    contactFormFullName.focus();
    scrollToSelector('#contact-us');
  }
  //#endregion

  //#region Templating
  return (
    <StyledDemoBar id="demobar" topBG={topBG} bottomBG={bottomBG} barBG={barBG}>
      <Wrapper>
        <ShowOnScroll delay={200}>
          <div className="bar">
            <div className="bar__left">
              <span className="version">{chordVersion}</span>
              <span className="title">{boxTitle}</span>
            </div>
            <div className="bar__right">
              <Form action="" className="form" customSubmit={syncEmail}>
                <input type="email" className="input" name="email" placeholder={formPlaceholder} />
                <StyledBtn type="submit">{formSubmit}</StyledBtn>
              </Form>
            </div>
          </div>
        </ShowOnScroll>
      </Wrapper>
    </StyledDemoBar>
  )
  //#endregion
}

export const DemoBar: React.FC<StaticQueryProps> = (props) => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/demobanner.md/"}) {
        frontmatter {
          boxTitle
          chordVersion
          formPlaceholder
          formSubmit
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} {...props} />} />
  //#endregion
}

export const DemoBarPreview: React.FC<DemoBarData> = (data: DemoBarData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

interface StaticQueryProps {
  topBG?: ThemeBG;
  barBG?: ThemeBG;
  bottomBG?: ThemeBG;
}

interface DemoBarProps extends StaticQueryProps {
  data: MarkdownData<DemoBarData>['data']
}

interface DemoBarData {
  boxTitle: string;
  chordVersion: string;
  formPlaceholder: string;
  formSubmit: string;
}