import styled from 'styled-components';
import { Wrapper } from '../../styles/Wrapper.style';
import { Clamp } from '../../utils/Tools';
import { DividerProps } from './Divider';

export const StyledDivider = styled.div<DividerProps>`
  padding: ${p => p.spacing}px 0;

  ${Wrapper} {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      height: 1px;
      left: var(--gutter);
      right: var(--gutter);
      background: var(--${p => p.color});
      opacity: ${p => Clamp(p.opacity, 0, 1)};
    }
  }
`;