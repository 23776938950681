import styled from 'styled-components';
import { desktopHover, pxtorem } from '../../utils/Tools';

export const StyledTeamListing = styled.div`
  padding: ${pxtorem(118)} 0 ${pxtorem(63)};
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 60px;
    grid-gap: 30px 20px;
  }

  .info {
    text-align: center;
    margin-top: 18px;
  }

  .media {
    border-radius: 8px;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);

    .previewImg {
      width: 100%;
    }
  }

  .mainInfo {
    font: 600 ${pxtorem(20)}/${pxtorem(24)} var(--primaryFont);
    letter-spacing: -0.05em;
  }
  
  a.mainInfo {
    transition: color .25s;
    
    border-bottom: 1px solid var(--pink);
    ${desktopHover(`
      --color: var(--pink);
    `)}
  }

  .prevRole {
    display: block;
    margin-top: 14px;
    font: 400 14px/20px var(--primaryFont);
    strong {
      font-weight: 600;
    }
  }

  @media (max-width: 991px) {
    .prevRole {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 48px 0;
    .items {
      grid-template-columns: 1fr 1fr;
    }
  }
`