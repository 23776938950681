import React from 'react';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledDivider } from './Divider.styled';

export const Divider: React.FC<DividerProps> = ({ spacing = 0, color = 'cream', width = 1320, opacity = 0.15 }) => {
  //#region Templating
  return (
    <StyledDivider spacing={spacing} color={color} opacity={opacity}>
      <Wrapper width={width}><div></div></Wrapper>
    </StyledDivider>
  )
  //#endregion
}

export interface DividerProps {
  spacing?: number;
  color?: ThemeBG;
  width?: number;
  opacity?: number;
}