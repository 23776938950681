import React, { useEffect } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { OffsetLine, OffsetLines } from '../OffsetLines/OffsetLines';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Wrapper } from '../../styles/Wrapper.style';
import { PactImage } from '../PactImage/PactImage';
import { StyledTeamListing } from './TeamListing.styled';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';


const Template = ({ data }: MarkdownData<TeamListingData>) => {
  //#region Variables
  const { titleLines, teamMembers, sectionId } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Templating
  return (
    <StyledTeamListing >
      <div id={sectionId}>
        <OffsetLines width={1320} yOffset={70} lines={titleLines} />
        <Wrapper width={1097}>
          <div className="items">
            {teamMembers.map((member, i: number) => {
              const { name, role, link, previousRole, photo } = member;
              return (
                <ShowOnScroll key={`teamMember-${i}`}>
                  <div className="item">
                    <div className="media">
                      <PactImage image={photo} alt={`${name} – ${role}`} />
                    </div>
                    <div className="info">
                      {link && link !== ''
                        ? <a href={link} target="_blank" className="mainInfo">{name}<br />{role}</a>
                        : <span className="mainInfo">{name}<br />{role}</span>
                      }

                      <div className="prevRole" dangerouslySetInnerHTML={{ __html: previousRole }}></div>
                    </div>
                  </div>
                </ShowOnScroll>
              )
            })}
          </div>
        </Wrapper>
      </div>
    </StyledTeamListing>
  )
  //#endregion
}

export const TeamListing: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/team.md/"}) {
        frontmatter {
          sectionId
          titleLines {
            offset
            text
          }
          teamMembers {
            name
            role
            link
            previousRole
            photo {
              childImageSharp {
                gatsbyImageData(
                  width: 700
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

export const TeamListingPreview: React.FC<TeamListingData> = (data: TeamListingData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

interface TeamListingData {
  titleLines: OffsetLine[];
  sectionId: string;
  teamMembers: {
    name: string;
    role: string;
    link: string;
    previousRole: string;
    photo: ImageDataLike | string;
  }[]
}