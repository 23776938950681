import { Wrapper } from '../../styles/Wrapper.style';
import { pxtorem } from '../../utils/Tools';
import styled from 'styled-components/macro';
import { StyledOffsetLine } from '../OffsetLines/OffsetLines.styled';

export const StyledHeroBanner = styled.div`
  padding: ${pxtorem(60)} 0 ${pxtorem(64)};
  position: relative; 
  ${Wrapper} {
    position: relative;
  }

  ${StyledOffsetLine} {
    position: relative;
    z-index: 3;
  }
  
  .boxWrapper {
    visibility: hidden;
    z-index: 2;
    opacity: 0;
    transform: translateY(100px);
  }

  .box {
    position: absolute;
    right: var(--gutter);
    bottom: 7.8472vw;
    z-index: 3;
    @media (min-width: 1440px) {
      bottom: 113px;
    }

    @media (max-width: 1200px) {
      bottom: 6.8472vw;
    }

    @media (max-width: 1024px) {
      bottom: 3.8472vw;
    }

    max-width: 381px;
    font: 400 14px/20px var(--primaryFont);

    &__title {
      font: inherit;
      font-weight: 600;
      margin: 0;
    }

    &__text {
      margin: 0;
    }
  }

  .circle1 {
    position: absolute;
    left: 0;
    transform: translateX(-40%);
    bottom: -231px;
    z-index: 2;
  }

  .circle2 {
    position: absolute;
    right: -250px;
    top: -20px;
    z-index: 2;
  }

  @media (max-width: 991px) {
    padding: 24px 0 32px;
    .box {
      margin-top: 24px;
      position: relative;
      right: unset;
      bottom: unset;
      font: 400 12px/18px var(--primaryFont);
    }

    .circle1 {
      bottom: -141px;
    }

    .circle2 {
      right: -57px;
      top: -68px;
    }
  }
`