import { pxtorem } from '../../utils/Tools';
import styled from 'styled-components/macro';

export const StyledValueProps = styled.div`
  padding: ${pxtorem(120)} 0;
  .item {
    padding: 24px 0;
    display: block;
    border-top: 1px solid rgba(35,35,35,0.15);
    &:last-child {
      border-bottom: 1px solid rgba(35,35,35,0.15);
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .value {
    display: block;
    padding-right: 15px;
    letter-spacing: -0.05em;
    font: 600 ${pxtorem(36)}/${pxtorem(44)} var(--primaryFont);
  }

  .prop {
    text-align: right;
    font: 400 12px/20px var(--secondaryFont);
  }

  @media (max-width: 768px) {
    padding: 33px 0;
    
    .item {
      display: block;
    }

    .value {
      padding-right: 0;
      font-size: 20px;
      line-height: 24px;
    }

    .prop {
      margin-top: 10px;
      font-size: 10px;
      line-height: 16px;
    }
  }
`;