import styled from 'styled-components';
import ArrowDown from '../../styles/svg/ArrowDown.svg';

export const StyledSelectHolder = styled.div`
  position: relative;
  
  select {
    width: 100%;
  }

  &:before {
    content: '';
    width: 8px;
    height: 14px;
    background-image: url(${ArrowDown});
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const StyledSelect = styled.select`
  transition: border .25s;
  appearance: none;
  background: transparent;
  color: var(--color);
  border: 1px solid var(--cream);
  border-radius: 8px;
  height: 52px;
  padding: 0 21px;
  font: 400 14px/20px var(--primaryFont);
  
  @media (max-width: 640px) {
    font: 400 16px/20px var(--primaryFont);
  }

  &::placeholder {
    color: rgba(249, 247, 238, 0.5);
  }

  &:focus {
    outline: 0;
    border-color: var(--pink);
  }

  @media (max-width: 640px) {
    height: 48px;
  }
`;