import styled from 'styled-components';
import { pxtorem } from '../../../utils/Tools';

export const StyledPricingCell = styled.div`
  font: 400 14px/1.4 var(--primaryFont);

  * {
    margin: 0;

    &:not(.price) {
      letter-spacing: 0px;
    }
  }

  .title {
    margin-bottom: 8px;
  }

  .price {
    display: inline-block;
    font: 600 ${ pxtorem(36) }/1.2 var(--primaryFont);
  }
`;
