import styled from 'styled-components';
import { Section } from '../../../styles/Section.style';

export const StyledComparisonTable = styled.div`
  --borderColor: rgba(249, 247, 238, 0);

  &.visible {
    --borderColor: rgba(249, 247, 238, .07);
  }

  ${ Section } {
    border-radius: 8px;
    padding: 0;
  }

  * {
    letter-spacing: -0.05em;
  }

  .grid {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto;

    &__cell {
      transition: border-color .2s linear;

      // Vertical grid borders
      &:not(:nth-child(3n)) {
        border-right: 1px solid var(--borderColor);
      }

      // Horizontal grid borders
      &:not(:nth-last-child(-n+3)) {
        border-bottom: 1px solid var(--borderColor);
      }
    }
  }
`;
