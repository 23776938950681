import styled from 'styled-components';
import { StyledPricingCell } from '../PricingCell/PricingCell.style';

export const StyledComparisonCard = styled.div`
  background: var(--charcoal);
  color: var(--cream);
  border-radius: 8px;
  padding: 32px 24px 44px;

  .cell {
    padding: 24px 0;
    border: none;

    &:first-child {
      padding: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &:not(:first-child) {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(249, 247, 238, .07);
      }
    }

    .title {
      font-size: 18px;
      margin-bottom: 24px;
    }

    &--head {
      .title {
        font-size: 32px;
        margin: 0;
      }
    }

    &--description {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      *:not(svg) {
        flex: 0 1 auto;
      }

      svg {
        display: block;
        fill: currentColor;
        flex: 0 0 31px;
        width: 31px;
        height: 35px;
        padding-left: 32px;
        box-sizing: content-box;
      }
    }

    .featureList {
      list-style: none;

      &__item {
        position: relative;
        font-size: 12px;
        line-height: 18px;
        padding-left: 17px;

        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 5px;
          height: 5px;
          left: 0;
          top: .5em;
          border-radius: 100%;
          background-color: var(--pink);
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  ${ StyledPricingCell } {
    .title {
      margin: 0;
    }

    .title.title, .description {
      font-size: 12px;
    }

    .price {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .fee {
      display: block;
    }
  }
`;
