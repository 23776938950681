import React from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { Wrapper } from '../../styles/Wrapper.style';
import { StyledValueProps } from './ValueProps.styled';


const Template = ({ data }: MarkdownData<ValuePropsProps>) => {
  //#region Variables
  const { sectionId, value_props } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Templating
  return (
    <StyledValueProps>
      <Wrapper id={sectionId}>
        <ul className="items">
          {value_props.map((vp, i: number) => (
            <li className="item" key={`value-prop-${i}`}>
              <span className="value">{vp.value_text}</span>
              <span className="prop">{vp.value_prop}</span>
            </li>
          ))}
        </ul>
      </Wrapper>
    </StyledValueProps>
  )
  //#endregion
}

export const Valueprops: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/valueprops.md/"}) {
        frontmatter {
          sectionId
          value_props {
            value_prop
            value_text
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

export const ValuepropsPreview: React.FC<ValuePropsProps> = (data: ValuePropsProps) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

interface ValuePropsProps {
  sectionId: string;
  value_props: {
    value_prop: string;
    value_text: string;
  }[];
}