import React, { HTMLAttributes } from 'react';
import { StyledPricingCell } from './PricingCell.style';

export const PricingCell = React.forwardRef<HTMLDivElement, PricingCellProps>(({ price, fee, ...props }, ref) => {
  return (
    <StyledPricingCell {...props} ref={ ref }>
      <p className="title">Starting at</p>

      <p className="description">
        <span className="price">{ price }&nbsp;</span>per month <span className="fee">+ { fee } transaction fee</span>
      </p>
    </StyledPricingCell>
  );
});

interface PricingCellProps extends HTMLAttributes<HTMLDivElement> {
  price: string;
  fee: string;
}
