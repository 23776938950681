import { pxtorem, desktopHover } from '../../utils/Tools';
import styled from 'styled-components';
import { StyledA, StyledBtn } from '../Cta/Cta.styled';
import { StyledShowOnScroll } from '../ShowOnScroll/ShowOnScroll.styled';

export const StyledFiftyFifty = styled.div`
  padding: 67px 0 91px;

  ${StyledShowOnScroll} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'content image';
    grid-gap: 11.5277vw 25px;
    align-items: center;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width:1440px) {
      grid-gap: 166px 25px;
    }

    &:nth-child(even) {
      grid-template-areas: 'image content';
    }
  }

  .content {
    grid-area: content;
    max-width: 534px;
  }

  .imageHolder {
    grid-area: 'image';
    img {
      max-width: 680px;
      &.previewImg {
        width: 100%;
      }
    }
  }

  .title {
    font: 600 ${pxtorem(52)} var(--primaryFont);
    letter-spacing: -0.05em;
    margin-top: 0;
    margin-bottom: 14px;
  }
  
  .text {
    max-width: 491px;
    font: 400 20px var(--primaryFont);
    opacity: 0.75;
    p {
      margin-top: 0;
    }

    a {
      --color: var(--pink);
      transition: .25s color, .25s border;
      border-bottom: 1px solid var(--color);
      font-family: var(--secondaryFont);

      ${desktopHover(`
        --color: var(--cream);
      `)}
    }
  }
  
  ${StyledA},${StyledBtn} {
    margin-top: 33px;
  }
  
  @media (max-width: 1024px) {
    .title {
      font: 600 32px/40px var(--primaryFont);
      margin-bottom: 16px;
    }

    .text {
      font: 400 12px/18px var(--primaryFont);
    }

    ${StyledA},${StyledBtn} {
      margin-top: 24px;
    }
  }

  @media (max-width: 768px) {
    padding: 32px 0 30px;
    
    ${StyledShowOnScroll} {
      grid-template-columns: 1fr;
      grid-template-areas: 'image' 'content';
      &:nth-child(even) {
        grid-template-areas: 'image' 'content';
      }
      grid-gap: 25px;
      margin-bottom: 30px;
    }

    .content {
      max-width: 100%;
    }

    .text {
      max-width: 100%;
    }
  }
`;