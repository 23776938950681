import React from 'react'

const CheckMark = React.forwardRef<SVGElement, CheckMarkProps>((props, ref) => {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 32" {...props} ref={ ref as any }>
      <path d="M39.928 0l-1.253 1.265c-9.126 9.237-15.879 16.793-24.725 25.814l-10.302-8.801-1.364-1.181-2.283 2.756 1.364 1.153 11.583 9.898 1.253 1.097 1.142-1.181c9.541-9.656 16.372-17.413 25.839-26.995l1.281-1.293-2.534-2.531z"></path>
    </svg>
  );
});

interface CheckMarkProps extends React.HTMLAttributes<SVGElement> { }

export default CheckMark;
