import React, { useEffect } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import { Wrapper } from '../../styles/Wrapper.style';
import { PactImage } from '../PactImage/PactImage';
import { Section } from '../../styles/Section.style';
import { StyledImageText } from './ImageText.styled';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';


const Template = ({ data }: MarkdownData<ImageTextData>) => {
  //#region Variables
  const { html } = data.markdownRemark || {};
  const { sectionId, subtitle, title, image } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Templating
  return (
    <StyledImageText>
      <ShowOnScroll>
        <Wrapper id={sectionId}>
          <div className="box">
            <div className="image">
              <PactImage image={image} alt={title} />
            </div>
            <Section bg="white">
              <div className="text">
                {subtitle && <span className="subtitle">{subtitle}</span>}
                <h2 className="title">{title}</h2>
                <div className="body" dangerouslySetInnerHTML={{ __html: html }}></div>
              </div>
            </Section>
          </div>
        </Wrapper>
      </ShowOnScroll>
    </StyledImageText>
  )
  //#endregion
}

export const ImageTextPreview: React.FC<ImageTextData> = (data: ImageTextData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

export const Imagetext: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/imagetextblock.md/"}) {
        html
        frontmatter {
          sectionId
          subtitle
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 1298
                layout: FULL_WIDTH
                placeholder: DOMINANT_COLOR
                transformOptions: {fit: COVER}
              )
            }
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

interface ImageTextData {
  subtitle: string;
  title: string;
  image: ImageDataLike | string;
  sectionId: string;
}