import { pxtorem } from '../../utils/Tools';
import styled from 'styled-components/macro';
import { StyledBtn } from '../Cta/Cta.styled';
import { Wrapper } from '../../styles/Wrapper.style';

export const StyledDemoBar = styled.div<StyledDemoBarProps>`
  background: var(--${p => p.topBG});
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom:-1px;
    left: 0;
    right: 0;
    height: 50%;
    background: var(--${p => p.bottomBG});
    z-index: 1;
  }

  ${Wrapper} {
    position: relative;
    z-index: 2;
  }

  .bar {
    --barGutter: 8.27%;
    padding: 30px var(--barGutter) 31px;
    background: var(--${p => p.barBG});
    border-radius: 8px;
    position: relative;
    z-index: 2;
    box-shadow: 0px 17px 48px -4px rgba(18, 61, 70, 0.07);
    display: grid;
    grid-template-columns: 1fr 37.7392%;
    grid-gap: 20px;
    align-items: center;

  }

  .version {
    font: 300 12px/20px var(--secondaryFont);
    display: block;
    margin-bottom: 3px;
    padding-left: 15px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.07);
      background: #38D1A6;
      border: 1px solid #38CDA3;
    }
  }

  .title {
    font: 600 ${pxtorem(36)}/${pxtorem(43.57)} var(--primaryFont);
    display: block;
    letter-spacing: -0.05em;
  }

  .form {
    padding: 8px 16px;
    border: 1px solid rgba(249, 247, 238, 0.75);
    display: flex;
    border-radius: 8px;
    width: 100%;
    height: 52px;
  }

  .input {
    background: transparent;
    border-radius: 0;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    margin-right: 10px;

    @media (max-width: 640px) {
      font-size: 16px;
    }

    &::placeholder {
      color: rgba(249, 247, 238, 0.75);
    }
  }

  ${StyledBtn} {
    --padding: 0px 12px;
  }

  @media (max-width: 1200px) {
    .bar {
      --barGutter: var(--gutter);
    }
  }

  @media (max-width: 991px) {
    .bar {
      grid-template-columns: 1fr 1fr;
    }

    .title {
      font: 600 20px/24px var(--primaryFont);
    }

    .version {
      &:before {
        top: 5px;
      }
    }
  }

  @media (max-width: 640px) {
    .bar {
      padding: 20px;
      grid-template-columns: 1fr;
    }

    .form {
      padding: 8px;
    }
  }

`

// export const StyledSubmit = styled(StyledCta).attrs({as: 'button'})``;

interface StyledDemoBarProps {
  topBG: ThemeBG;
  barBG: ThemeBG;
  bottomBG: ThemeBG;
}