import React, { useEffect, useRef, useState } from 'react';
import { StyledComparisonTable } from './ComparisonTable.styled';
import { Section } from '../../../styles/Section.style';
import CheckMark from '../../../styles/svg/CheckMark';
import ShowOnScroll from '../../ShowOnScroll/ShowOnScroll';
import { ComparisonTableData } from '../index';
import { ComparisonCell } from '../Comparison.style';
import { PricingCell } from '../PricingCell/PricingCell';
import { animateChildren, resetAnimationStyles } from '../animateChildren';

export const ComparisonTable: React.FC<ComparisonTableProps> = (props) => {
  const {
    frontendTitle,
    frontendDescription,
    completeTitle,
    completeDescription,
    features = [],
    frontendPrice,
    frontendTransactionFee,
    completePrice,
    completeTransactionFee
  } = props ?? {};

  //#region Hooks / Lifecycles
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const cellWrappersRef = useRef<Element[]>([]);

  useEffect(() => {
    init();

    if (cellWrappersRef.current.length) {
      resetAnimationStyles(cellWrappersRef.current);
    }

    return () => {
      destroy();
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      animateChildren(cellWrappersRef.current);
    }
  }, [isVisible]);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => { }

  const destroy = (): void => { }

  function addToRefs(ref: HTMLElement) {
    if (!cellWrappersRef.current.includes(ref)) {
      cellWrappersRef.current.push(ref);
    }
  }

  function onVisible(): void {
    animateChildren(cellWrappersRef.current);
    setIsVisible(true);
  }
  //#endregion

  //#region Templating
  return (
    <StyledComparisonTable className={isVisible ? 'visible' : ''}>
      <ShowOnScroll
        passive={true}
        delay={0}
        onVisible={onVisible}>
        <Section bg="charcoal">
          <div className="grid">
            {/* Table head */}
            <div className="grid__cell grid__cell--head"></div>

            <ComparisonCell type="head" className="grid__cell grid__cell--head">
              <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                <h2 className="grid__cell-title">{frontendTitle}</h2>
                <p className="grid__cell-description">{frontendDescription}</p>
              </div>
            </ComparisonCell>

            <ComparisonCell type="head" className="grid__cell grid__cell--head">
              <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                <h2 className="grid__cell-title">{completeTitle}</h2>
                <p className="grid__cell-description">{completeDescription}</p>
              </div>
            </ComparisonCell>
            {/* Table head - END */}

            {/* Table cells */}
            {features.map((feat, i) => {
              const { featureTitle, featuresList, isOnFrontend, isOnComplete } = feat;

              return (
                <React.Fragment key={`comparison-item-${i}`}>
                  <ComparisonCell key={featureTitle} className="grid__cell">
                    <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                      <h3 className="grid__cell-title">{featureTitle}</h3>
                      {featuresList?.length && (
                        <ul className="featureList">
                          {featuresList.map(i => {
                            return <li className="featureList__item" key={i.feature}>{i.feature}</li>;
                          })}
                        </ul>
                      )}
                    </div>
                  </ComparisonCell>

                  <ComparisonCell type="icon" className="grid__cell grid__cell--icon">
                    <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                      {isOnFrontend && (
                        <CheckMark className="grid__check-icon" />
                      )}
                    </div>
                  </ComparisonCell>

                  <ComparisonCell type="icon" className="grid__cell grid__cell--icon">
                    <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                      {isOnComplete && (
                        <CheckMark className="grid__check-icon" />
                      )}
                    </div>
                  </ComparisonCell>
                </React.Fragment>
              );
            })}
            {/* Table cells - END */}

            {/* Table footer */}
            <ComparisonCell type="pricing" className="grid__cell grid__cell--pricing" />

            <ComparisonCell type="pricing" className="grid__cell grid__cell--pricing">
              <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                <PricingCell price={frontendPrice} fee={frontendTransactionFee} />
              </div>
            </ComparisonCell>

            <ComparisonCell type="pricing" className="grid__cell grid__cell--pricing">
              <div className="grid__cell-wrapper" ref={ref => addToRefs(ref)}>
                <PricingCell price={completePrice} fee={completeTransactionFee} />
              </div>
            </ComparisonCell>
            {/* Table footer - END */}
          </div>
        </Section>
      </ShowOnScroll>
    </StyledComparisonTable>
  )
  //#endregion
}

interface ComparisonTableProps extends Partial<ComparisonTableData> { }
