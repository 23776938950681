import styled from 'styled-components';
import { desktopHover } from '../../utils/Tools';

export const StyledTestimonials = styled.div`
  padding: 82px 0 85px;
  .items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px 20px;
  }

  .logo {
    .previewImg {
      max-width: 100px;
    }

    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering: -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
  }

  .quote {
    margin: 0;
    opacity: 0.75;
    margin-bottom: 16px;
    max-width: 420px;
  }

  .avatar {
    border-radius: 100%;
    overflow: hidden;
    width: 49px;
    height: 49px;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering: -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    // safari fix
    backface-visibility: hidden;
    transform: translateZ(0);
  }

  .info {
    display: grid;
    grid-template-columns: 49px 1fr;
    grid-gap: 16px;
    align-items: center;
    font: 400 14px/17px var(--primaryFont);
  }

  .author {
    display: block;
  }

  .role {
    display: block;

    a {
      transition: color .25s;
      border-bottom: 1px solid var(--color);
      white-space: nowrap;
      ${desktopHover(`
        --color: var(--pink);
      `)}
    }
  }

  @media (max-width: 991px) {
    padding: 40px 0;
    .quote {
      font: 400 12px/18px var(--primaryFont);
    }

    .info {
      font: 400 12px/20px var(--primaryFont);
    }
  }

  @media (max-width: 768px) {
    .items {
      grid-template-columns: 1fr;
    }
  }
`