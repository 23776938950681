import React from 'react'

const ShopifyLogo = React.forwardRef<SVGElement, ShopifyLogoProps>((props, ref) => {
  return (
    <svg viewBox="0 0 31 36" xmlns="http://www.w3.org/2000/svg" ref={ ref as any } { ...props }>
      <path d="M20.7959 4.14262L19.7633 4.46353C19.6485 4.02593 19.4764 3.6175 19.2756 3.20908C18.5299 1.77958 17.4686 1.02107 16.1778 1.02107C16.0917 1.02107 16.0057 1.02106 15.9196 1.05024C15.8909 0.991889 15.8336 0.962716 15.8049 0.904369C15.2312 0.291727 14.5141 0 13.6536 0C11.9612 0.0583468 10.2976 1.28363 8.94941 3.47164C8.00283 5.01783 7.25705 6.94327 7.05626 8.46029L3.7576 9.51053C2.78235 9.83144 2.75366 9.86062 2.63893 10.7942C2.55288 11.4943 0 31.5365 0 31.5365L21.1401 35.2415V4.11345C20.968 4.11345 20.8819 4.11345 20.7959 4.14262ZM15.9196 5.68882L12.3628 6.82658C12.707 5.4846 13.3667 4.14262 14.1699 3.26742C14.4567 2.94651 14.887 2.56725 15.3746 2.36304C15.8336 3.35494 15.9196 4.72609 15.9196 5.68882ZM13.6249 1.16694C13.9691 1.13776 14.342 1.25446 14.6288 1.4295C14.1412 1.69206 13.711 2.04214 13.3381 2.45057C12.2768 3.6175 11.4449 5.42625 11.1294 7.17665L8.17494 8.11021C8.7773 5.36791 11.0147 1.25446 13.6249 1.16694ZM10.3549 16.8039C10.4697 18.6418 15.2025 19.0211 15.4607 23.3096C15.6615 26.6937 13.711 28.9984 10.8712 29.1734C7.45784 29.4068 5.59338 27.3647 5.59338 27.3647L6.31048 24.2431C6.31048 24.2431 8.20362 25.7018 9.69519 25.5851C10.6704 25.5267 11.0433 24.7099 10.986 24.1264C10.8426 21.7342 6.9989 21.8801 6.74074 17.9708C6.53995 14.6742 8.66257 11.3193 13.3667 11.0275C15.1738 10.9109 16.0917 11.3776 16.0917 11.3776L15.0304 15.4619C15.0304 15.4619 13.8257 14.9076 12.4202 14.9951C10.3549 15.141 10.3262 16.483 10.3549 16.8039ZM17.0383 5.33873C17.0383 4.46353 16.9236 3.26742 16.522 2.21717C17.7841 2.45056 18.4151 3.93841 18.702 4.81361C18.6733 4.81361 17.0383 5.33873 17.0383 5.33873ZM21.7425 35.1831L30.5198 32.966C30.5198 32.966 26.7335 7.00162 26.7335 6.82658C26.7048 6.68071 26.5901 6.56402 26.418 6.53484C26.3032 6.53484 23.8364 6.47649 23.8364 6.47649C23.8364 6.47649 22.3448 4.98865 21.7712 4.43435V35.1831H21.7425Z" />
    </svg>
  );
});

interface ShopifyLogoProps extends React.HTMLAttributes<SVGElement> { }

export default ShopifyLogo;
