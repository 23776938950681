import React from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import { Wrapper } from '../../styles/Wrapper.style';
import { Cta } from '../Cta/Cta';
import { StyledFiftyFifty } from './FiftyFifty.styled';
import { PactImage } from '../PactImage/PactImage';
import ShowOnScroll from '../ShowOnScroll/ShowOnScroll';

const Template = ({ data }: MarkdownData<FiftyFiftyData>) => {
  //#region Variables
  const { fiftyFiftyBlocks } = data.markdownRemark?.frontmatter || {};
  //#endregion

  //#region Templating
  // Make sure to fallback to src if no obj (img)
  return (
    <StyledFiftyFifty>
      <Wrapper>
        <div className="items">
          {fiftyFiftyBlocks.map((block, i: number) => {
            const { title, body, ctaLabel, ctaLink, sectionId, image } = block;
            return (

              <ShowOnScroll key={`fifty-fifty-${i}`}>
                <div className="content" id={sectionId}>
                  <h2 className="title">{title}</h2>
                  <div className="text">
                    <ReactMarkdown>{body}</ReactMarkdown>
                  </div>
                  {ctaLink && ctaLabel && (
                    <Cta size="wide" href={ctaLink}>{ctaLabel}</Cta>
                  )}
                </div>
                <div className="imageHolder">
                  <PactImage image={image} alt="" />
                </div>
              </ShowOnScroll>
            )
          })}
        </div>
      </Wrapper>
    </StyledFiftyFifty>
  )
  //#endregion
}

export const Fiftyfifty: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/fiftyfifty.md/"}) {
        frontmatter {
          fiftyFiftyBlocks {
            title
            body
            ctaLabel
            ctaLink
            sectionId
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}

export const FiftyFiftyPreview: React.FC<FiftyFiftyData> = (data: FiftyFiftyData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

interface FiftyFiftyData {
  fiftyFiftyBlocks: {
    title: string;
    body: string;
    ctaLabel: string;
    ctaLink: string;
    sectionId: string;
    image: any;
  }[]
}