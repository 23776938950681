import React, { useRef } from 'react';
import { slugify } from '../../utils/Tools';
import type { InputProps } from './Input';
import { StyledSelectHolder, StyledSelect } from './Select.styled';

export const Select: React.FC<SelectProps> = ({
  name,
  placeholder,
  id,
  className,
  required = false,
  onChange,
  choices = []
}) => {

  const _onInput = (e: React.FormEvent) => {
    if (onChange) {
      onChange(e);
    }
  }
  //#region Templating
  return (
    <StyledSelectHolder>
      <StyledSelect
        className={className}
        name={name}
        id={id || slugify(name)}
        defaultValue=""
        onInput={_onInput}
        required={required}>
        {placeholder && <option value="" disabled>{placeholder}</option>}
        {choices.map((choice, i: number) => (
          <option key={`select-${id || slugify(name)}-choice-${i}`} value={choice.value || choice.label}>{choice.label}</option>
        ))}
      </StyledSelect>
    </StyledSelectHolder>
  )
  //#endregion
}

interface SelectProps extends InputProps {
  choices: {
    label: string;
    value?: string;
  }[]
}