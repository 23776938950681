import React, { useRef } from 'react';
import { slugify } from '../../utils/Tools';
import { StyledInput } from './Input.styled';

export const Input: React.FC<InputProps> = ({
  name,
  type = 'text',
  placeholder,
  id,
  value,
  maxlength,
  className,
  onChange,
  required = false
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const _onInput = (e: React.FormEvent) => {
    if (onChange) {
      onChange(e);
    }
  }
  //#region Templating
  return (
    <StyledInput
      type={type}
      className={className}
      name={name}
      maxLength={maxlength}
      id={id ? id : slugify(name)}
      onInput={_onInput}
      value={value}
      required={required}
      placeholder={placeholder && type !== 'hidden' ? placeholder : name} />
  )
  //#endregion
}

export interface InputProps {
  name: string;
  type?: "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url";
  placeholder?: string;
  id?: string;
  className?: string;
  value?: string;
  required?: boolean;
  maxlength?: number;
  onChange?(e: React.FormEvent): void;
}