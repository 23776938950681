import styled from 'styled-components';
import { Section } from '../../styles/Section.style';
import { desktopHover, pxtorem } from '../../utils/Tools';
import { StyledPactImage } from '../PactImage/PactImage.styled';

export const StyledImageText = styled.div`
  padding: ${pxtorem(84)} 0;

  .box {
    display: grid;
    grid-template-columns: 1fr 50.6828%;
    border-radius: 8px;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translateZ(0);
    box-shadow: 0px 17px 48px -4px rgba(12, 37, 46, 0.1);
  }

  .text, .image, ${StyledPactImage}, .gatsby-image-wrapper {
    height: 100%;
  }

  .text {
    padding: ${pxtorem(70)} 6.25vw;
    @media (min-width: 1440px) {
      padding: 70px 90px;
    }
  }

  .subtitle {
    font: 400 12px/20px var(--secondaryFont);
    display: block;
    margin-bottom: 22px;
  }

  .title {
    font: 600 ${pxtorem(56)} var(--primaryFont);
    letter-spacing: -0.05em;
    text-decoration: underline;
    text-decoration-color: var(--pink);
    margin-bottom: 26px;
  }

  .body {
    font: 400 1rem/${pxtorem(30)} var(--primaryFont);
    p {
      margin-top: 0;
      margin-bottom: 10px;
      
      &:first-child {
        margin-bottom: 25px;
      }
      
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      transition: color .25s;
      --color: var(--pink);
      text-decoration: underline;
      font: 400 12px/20px var(--secondaryFont);

      ${desktopHover(`--color: var(--charcoal);`)}
    }
  }

  @media (max-width: 991px) {
    padding: 40px 0;
    .box {
      grid-template-columns: 1fr;
    }

    .image {
      height: 335px;
    }

    .text {
      padding: 24px;
    }

    .subtitle {
      font-size: 10px;
      line-height: 16px;
      margin-bottom: 12px;
    }

    .title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px;
    }

    .body {
      p {
        margin-bottom: 16px;
        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
`