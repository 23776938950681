import gsap from 'gsap';

export function resetAnimationStyles(children: Element[]): void {
  gsap.set(children, {
    alpha: .0001
  });
}

export function animateChildren(children: Element[], grid?: [number, number]): void {
  gsap.fromTo(children, {
    y: 24,
    alpha: .0001
  }, {
    y: 0,
    alpha: 1,
    duration: 1,
    ease: 'power4.out',
    stagger: {
      amount: 0.5,
      grid,
      from: 0
    }
  });
}