import styled from 'styled-components';
import { pxtorem } from '../../utils/Tools';
import { StyledBtn } from '../Cta/Cta.styled';
import { StyledForm } from '../Form/Form.styled';

export const StyledContactForm = styled.div`
  background: var(--black);
  position: relative;
  padding: 1px 0;
  
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    height: 58.77%;
    background: var(--background);
  }

  .box {
    --color: var(--cream);
    background: var(--charcoal);
    border-radius: 8px;
    padding: 53px var(--gutter);
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    color: var(--color);
    font: 600 ${pxtorem(36)} var(--primaryFont);
    margin-bottom: ${pxtorem(48)};
  }

  .text {
    color: var(--color);
    margin-top: -30px;
    margin-bottom: 0;
  }

  ${StyledForm} {
    width: 100%;
    max-width: 988px;
  }

  .inputGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px 22px;
  }

  ${StyledBtn} {
    grid-column: 2;
    height: 52px;
  }

  .recaptcha {
    grid-column: 1 / span 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .recaptchaError {
    font-size: 14px;
    color: red;
    display: none;
    &.visible {
      display: block;
    }
  }

  @media (max-width: 900px) {
    .inputGrid {
      grid-template-columns: 1fr 1fr;
    }

    ${StyledBtn} {
      grid-column: 1;
    }

    .recaptcha {
      grid-column: 1;
    }
  }

  @media (max-width: 640px) {
    .inputGrid {
      grid-template-columns: 1fr;
    }

    ${StyledBtn} {
      height: 48px;
    }
  }
`;