import React, { useEffect, useState, useRef } from 'react';
import { graphql, StaticQuery, StaticQueryDocument } from 'gatsby';
import { Wrapper } from '../../styles/Wrapper.style';
import { ComparisonTable } from './ComparisonTable/ComparisonTable';
import { ComparisonTableData } from './index';
import { ComparisonCard } from './ComparisonCard/ComparisonCard';
import { StyledComparison } from './Comparison.style';
import ShopifyLogo from '../../styles/svg/ShopifyLogo';
import { MaybeWindow } from '../../utils/Tools';
import { OffsetLines } from '../OffsetLines/OffsetLines';
import { StackedCircles } from '../StackedCircles/StackedCircles';

const Template = ({ data }: MarkdownData<ComparisonTableData>) => {
  const {
    frontendTitle,
    frontendDescription,
    completeTitle,
    completeDescription,
    features = [],
    frontendPrice,
    frontendTransactionFee,
    sectionId,
    completePrice,
    completeTransactionFee,
    titleLines
  } = data.markdownRemark?.frontmatter ?? {};

  //#region Hooks / Lifecycles
  const mediaQueryRef = useRef<MediaQueryList>();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  useEffect(() => {
    init();

    return () => {
      destroy();
    }
  }, []);
  //#endregion

  //#region Variables

  //#endregion

  //#region Functions
  const init = (): void => {
    if (MaybeWindow) {
      mediaQueryRef.current = MaybeWindow.matchMedia('(max-width: 1149px)');
      mediaQueryRef.current.addEventListener('change', onMediaQueryChange);
      setIsMobile(mediaQueryRef.current.matches);
    }
  }

  const destroy = (): void => {
    if (mediaQueryRef.current) {
      mediaQueryRef.current.removeEventListener('change', onMediaQueryChange);
    }
  }

  function onMediaQueryChange(evt: MediaQueryListEvent): void {
    setIsMobile(evt.matches);
  }
  //#endregion

  //#region Templating
  return (
    <StyledComparison isMobile={isMobile} id={sectionId}>
      <OffsetLines width={1320} yOffset={70} lines={titleLines} />
      <Wrapper className="comparison-wrapper">
        <div className="circle1">
          <StackedCircles waitUntilVisible={true} rotate={{ x: 34, y: -36, z: 0 }} />
        </div>
        {!isMobile ? (
          <ComparisonTable
            frontendTitle={frontendTitle}
            frontendDescription={frontendDescription}
            completeTitle={completeTitle}
            completeDescription={completeDescription}
            features={features}
            frontendPrice={frontendPrice}
            frontendTransactionFee={frontendTransactionFee}
            completePrice={completePrice}
            completeTransactionFee={completeTransactionFee} />
        ) : (
          <div className="comparison-cards">
            <ComparisonCard
              title={frontendTitle}
              price={frontendPrice}
              fee={frontendTransactionFee}
              features={features.filter(i => i.isOnFrontend)}
              description={() => (
                <>
                  <p className="description">{frontendDescription}</p>
                  <ShopifyLogo />
                </>
              )} />

            <ComparisonCard
              title={completeTitle}
              price={completePrice}
              fee={completeTransactionFee}
              features={features.filter(i => i.isOnComplete)}
              description={() => <p className="description">{completeDescription}</p>} />
          </div>
        )}
        <div className="circle2">
          <StackedCircles waitUntilVisible={true} rotate={{ x: 34, y: -36, z: 0 }} />
        </div>
      </Wrapper>
    </StyledComparison>
  )
  //#endregion
}

export const ComparisonPreview: React.FC<ComparisonTableData> = (data: ComparisonTableData) => <Template data={{
  markdownRemark: {
    frontmatter: data
  }
}} />

export const Comparison: React.FC = () => {
  //#region Query
  const query: StaticQueryDocument = graphql`
    {
      markdownRemark(fileAbsolutePath: {regex: "/comparison.md/"}) {
        frontmatter {
          sectionId
          titleLines {
            offset
            text
          }
          frontendTitle
          frontendDescription
          completeTitle
          completeDescription
          features {
            isOnFrontend
            isOnComplete
            featureTitle
            featuresList {
              feature
            }
          }
          frontendPrice
          frontendTransactionFee
          completePrice
          completeTransactionFee
        }
      }
    }
  `;
  //#endregion

  //#region Templating
  return <StaticQuery query={query} render={data => <Template data={data} />} />
  //#endregion
}
